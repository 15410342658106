import {createRouter, createWebHashHistory} from "vue-router";
import ChatMain from "../components/ChatMain.vue";
import LoginPage from "../components/LoginPage.vue";

const routes = [
    {
        path: '/',
        redirect: '/chat' },
    {
        path: "/chat",
        name: "ChatMain",
        component: ChatMain
    },
    {
        path: "/login",
        name: "LoginPage",
        component: LoginPage
    }
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});

export default router;
