<template>
  <div>
    <div style="display: flex;justify-content: center;margin-top: 150px">
      <el-form ref="loginForm" :model="form" label-width="80px" class="login-box">
        <h3 class="login-title" style="display: flex;justify-content: center;margin-top: 150px">欢迎登录</h3>
        <el-form-item label="账号">
          <el-input type="text" placeholder="请输入账号" v-model="form.username"/>
        </el-form-item>
        <el-form-item label="密码">
          <el-input type="password" placeholder="请输入密码" v-model="form.password"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loading" @click="onSubmit">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "LoginPage",
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      loading: false,
    }
  },
  methods: {
    onSubmit() {
      let self = this
      self.loading = true
      axios.post(process.env.VUE_APP_BASE_API + '/api/v1/security/login', {
        username: this.form.username,
        password: this.form.password,
        provider: "ldap",
        refresh: true
      }).then(function (response) {
        localStorage.setItem("access_token", response.data.data.access_token)
        localStorage.setItem("refresh_token", response.data.data.refresh_token)
        localStorage.setItem("user", response.data.data.user)
        self.$router.push("/chat");
      }).catch(function (error) {
        console.log(error);
      }).finally(function (){
        self.loading = false
      });
    }
  }
}
</script>

<style>
</style>
