<template>
  <div>
    <vue-advanced-chat
        height="calc(100vh - 20px)"
        :current-user-id="currentUserId"
        :rooms="JSON.stringify(rooms)"
        :rooms-loaded="roomsLoaded"
        :messages="JSON.stringify(messages)"
        :messages-loaded="messagesLoaded"
        @send-message="sendMessage($event.detail[0])"
        @fetch-messages="fetchMessages($event.detail[0])"
        :custom-search-room-enabled="false"
        @add-room="openAddConversationDialog"
        :show-files="true"
        :show-audio="false"
        :menu-actions="JSON.stringify(menuActions)"
        @menu-action-handler="menuActionHandler($event.detail[0])"
        :message-actions="JSON.stringify(messageActions)"
        :message-selection-actions="JSON.stringify(messageSelectActions)"
        :show-reaction-emojis="false"
    />
    <el-dialog v-model="createConversationDialogVisible" title="创建会话" width="70%">
      <el-form :model="createConversationForm">
        <el-form-item label="会话名称">
          <el-input v-model="createConversationForm.name" autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="createConversationDialogVisible = false">取消</el-button>
        <el-button type="primary" :loading="createConversationForm.loading" @click="addConversation">
          确认
        </el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { register } from '../lib/index'
import axios from "axios";
import router from "@/router";
register()

axios.interceptors.request.use(
    config => {
      if (localStorage.getItem('refresh_token') && config.url === process.env.VUE_APP_BASE_API + '/api/v1/security/refresh') {
        config.headers.Authorization = 'Bearer ' + localStorage.getItem('refresh_token');
      } else if (localStorage.getItem('access_token')) {
        config.headers.Authorization = 'Bearer ' + localStorage.getItem('access_token');
      } else {
        router.push('/login');
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
)

axios.interceptors.response.use(
    response => {
      console.log(response)
      return response;
    },
    error => {
      console.log(error)
      if (error.response) {
        switch (error.response.status) {
          case 440:
            if(localStorage.getItem('refresh_token')) {
              console.log("刷新token")
              axios.post(process.env.VUE_APP_BASE_API + '/api/v1/security/refresh', {}
              ).then(function (response) {
                localStorage.setItem("access_token", response.data.data)
              }).catch(function (error) {
                console.log(error);
              });
              return axios(error.response.config)
            } else {
              router.push("/login");
            }
            break
          case 401:
            router.push("/login");
            break
        }
      }
      return Promise.reject(error)   // 返回接口返回的错误信息
    }
)

export default {
  data() {
    return {
      currentUserId: localStorage.getItem('user'),
      rooms: [],
      messages: [],
      messagesLoaded: false,
      roomsLoaded: false,
      menuActions :[
        {
          name: 'editConversationName',
          title: '修改会话名称'
        },
        {
          name: 'truncateConversation',
          title: '清空会话'
        },
        {
          name: 'removeConversation',
          title: '删除会话'
        }
      ],
      messageActions:[
        {
          name: 'deleteMessage',
          title: '删除消息'
        },
        {
          name: 'selectMessages',
          title: '选择消息'
        }
      ],
      messageSelectActions:[
        {
          name: 'deleteMessage',
          title: '生成图片'
        },
      ],
      createConversationDialogVisible: false,
      createConversationForm: {
        name : "",
        loading: false
      }
    }
  },

  methods: {

    fetchMessages({ room, options }) {
      console.log('fetchMessages')
      console.log(room)
      console.log(options)
      this.messagesLoaded = false
      let self = this
      self.messages = []
      axios.get(process.env.VUE_APP_BASE_API + '/api/v1/conversation/'+ room.roomId +'/message', {
      }).then(function (response) {
        console.log(response);
        if(response.data.data == null)  {
          console.log("空消息")
        } else {
          response.data.data.forEach(function (element) {
            self.messages = [
              ...self.messages,
              {
                _id: element.id,
                content: element.content,
                senderId: element.sender_id,
                timestamp: element.timestamp,
                date: element.date
              }
            ]
          })
        }

        console.log(self.messages)
      }).catch(function (error) {
        console.log(error);
      }).finally(function (){
        console.log("空消息 end")
        self.messagesLoaded = true
      });

    },

    //发送消息
    sendMessage(message) {
      this.messages = [
        ...this.messages,
        {
          _id: this.messages.length,
          content: message.content,
          senderId: this.currentUserId,
          timestamp: new Date().toString().substring(16, 21),
          date: new Date().toDateString()
        }
      ]

      console.log(message)

      let self = this
      axios.post(process.env.VUE_APP_BASE_API + '/api/v1/conversation/'+ message.roomId +'/message', {
        conversation_id: message.roomId,
        content: message.content,
      }).then(function (response) {
        console.log(response);
        self.messages = [
          ...self.messages,
          {
            _id: "1",
            content: response.data.data,
            senderId: "assistant",
            timestamp: new Date().toString().substring(16, 21),
            date: new Date().toDateString()
          }
        ]
      }).catch(function (error) {
        console.log(error);
      }).finally(function (){

      });
    },

    openAddConversationDialog() {
      this.createConversationDialogVisible = true
    },

    addConversation() {
      let self = this
      self.createConversationForm.loading = true
      axios.post(process.env.VUE_APP_BASE_API + '/api/v1/conversation', {
        conversation_name: self.createConversationForm.name,
      }).then(function () {
        self.createConversationDialogVisible = false
        self.refreshConversation()
      }).catch(function (error) {
        console.log(error);
      }).finally(function (){
        self.createConversationForm.loading = false
        self.createConversationForm.name = ""
      });
    },

    refreshConversation() {
      let self = this
      axios.get(process.env.VUE_APP_BASE_API + '/api/v1/conversation',
      ).then(function (response) {
        console.log(response.data);
        console.log(self.rooms);
        self.rooms = response.data.data.map(function (element) {
          return {
            roomId: element.conversation_id,
            roomName: element.conversation_name,
            users: []
          }
        })
        console.log(self.rooms);
      }).catch(function (error) {
        console.log(error);
      }).finally(function (){
        self.roomsLoaded = true
      });
    },

    menuActionHandler({ roomId, action }) {
      console.log(roomId)
      console.log(action)
      let self = this;
      switch (action.name) {
        case "truncateConversation":
          axios.put(process.env.VUE_APP_BASE_API + '/api/v1/conversation/'+ roomId, {
            truncate: true
          }).then(function () {
            self.messages = []
          }).catch(function (error) {
            console.log(error);
          }).finally(function (){

          });

          break
        case "editConversationName":
          break
        case "removeConversation":
          axios.delete(process.env.VUE_APP_BASE_API + '/api/v1/conversation/'+ roomId, {
          }).then(function () {
            self.messages = []
            self.refreshConversation()
          }).catch(function (error) {
            console.log(error);
          }).finally(function (){

          });
          break
      }
    }
  },
  beforeMount() {
    this.refreshConversation()
  }
}
</script>

<style lang="scss">

</style>
